<template>
    <div class="detail center">
        <bread-crumb v-show="isShowBread"></bread-crumb>
        <div class="detail-box">
			<div class="btn-back">
				<el-button type="primary" @click="toPre" icon="el-icon-back" >返回</el-button>
			</div>
          <article-show></article-show>
        </div>
    </div>
</template>
<script>
import breadCrumb from '@/components/common/breadCrumb'
import ArticleShow from "@/components/articleShow";


export default {
  components: {
    ArticleShow,
    breadCrumb
  },
  data() {
    return {
        isShowBread:false,
    }
 },
  mounted() {
  },
  methods: {
    toPre() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.detail-box {

  .btn-back {
    position: absolute;
    z-index: 9999;
    top: 210px;

    .el-button {
      width: 80px;
      height: 35px;
      line-height: 35px;
      padding: 0px 0px;
    }
  }
}
</style>
